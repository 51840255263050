<template>
  <Fragment>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-btn @click.stop="modalFormulario = true" class="primary" block>
          <v-icon color="white"> mdi-mail </v-icon> &nbsp &nbsp &nbsp Enviar
          correo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-dialog v-model="modalFormulario" persistent>
          <v-card>
            <v-card-title class="secondary white--text">
              <h3 class="text-center">Enviar correos a proveedores</h3>
            </v-card-title>
            <v-card-text class="pt-6">
              <v-row>
                <v-col cols="6" md="2">
                  <v-switch
                    v-model="form.especifica"
                    label="Notificación específica"
                  />
                </v-col>
                <v-col v-if="form.especifica">
                  <v-autocomplete
                    outlined
                    :items="listado_acciones"
                    item-text="texto"
                    item-value="accion"
                    clearable
                    v-model="form.accion"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  !form.especifica ||
                  form.accion === 'notificacion_enviar_adjunto' ||
                  form.accion === 'notificacion_enviar_adjunto_multiples'
                "
              >
                <v-col cols="6" md="2">
                  <v-switch
                    v-model="form.todos"
                    label="Notificar a todos los perfiles"
                  />
                </v-col>
                <v-col>
                  <v-autocomplete
                    v-if="!form.todos"
                    v-model="form.perfiles"
                    :items="perfiles"
                    item-text="nombre"
                    item-value="id"
                    outlined
                    clearable
                    label="Seleccione los perfiles a los que notificará"
                    multiple
                    chips
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  form.especifica &&
                  (form.accion === 'notificacion_enviar_adjunto' ||
                    form.accion === 'notificacion_enviar_adjunto_multiples')
                "
              >
                <v-col cols="12" md="12">
                  <v-file-input
                    accept=".pdf, .docx"
                    v-model="form.adjunto"
                    outlined
                    required
                    small-chips
                    show-size
                    label="Favor anexe el documento adjunto"
                    :multiple="
                      form.accion == 'notificacion_enviar_adjunto_multiples'
                    "
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.asunto"
                    outlined
                    clearable
                    label="Asunto del correo"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <app-rich-text-component v-model="form.mensaje" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-accions>
              <v-btn class="secondary" @click.stop="validarForm">
                Enviar
              </v-btn>
              <v-btn @click.stop="cerrarForm">Cerrar</v-btn>
            </v-card-accions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <ConfirmationDialog
          :show="modalConfirmacion"
          title="Deseas enviar este correo?"
          message="Se enviará a los perfiles seleccionados."
          btnConfirmar="ENVIAR"
          @close="modalConfirmacion = false"
          @confirm="enviarCorreo"
        />
      </v-col>
    </v-row>
  </Fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { convertToFormData } from "@/utils/data";

export default {
  components: { Fragment, ConfirmationDialog },
  name: "enviarCorreosAdmin",
  data: () => ({
    form: {
      perfiles: [],
      todos: false,
      especifica: false,
      mensaje: "",
      asunto: "",
      accion: null,
      adjunto: null,
    },
    modalFormulario: false,
    modalConfirmacion: false,
    perfiles: [],
    listado_acciones: [
      {
        accion: "notificacion_procesos_contratacion",
        texto: "Notificar de procesos pendientes de pasar a contratación",
      },
      {
        accion: "notificacion_enviar_adjunto",
        texto: "Envio de correo con un adjunto",
      },
      {
        accion: "notificacion_enviar_adjunto_multiples",
        texto: "Envio de correo con múltiples",
      },
    ],
  }),
  methods: {
    validarForm() {
      if (this.form.especifica && !this.form.accion) {
        return this.pushAppMessage({
          type: "warning",
          message:
            "Si es una notificación específica, debe decir el tipo de acción",
        });
      } else if (
        !this.form.especifica &&
        !this.form.todos &&
        this.form.perfiles.length === 0
      ) {
        return this.pushAppMessage({
          type: "warning",
          message: "Seleccione al menos un perfil",
        });
      }
      if (this.form.mensaje === "") {
        return this.pushAppMessage({
          type: "warning",
          message: "Ingrese un mensaje",
        });
      }
      if (this.form.asunto === "") {
        return this.pushAppMessage({
          type: "warning",
          message: "Ingrese el asunto",
        });
      }
      if (
        this.form.accion === "notificacion_enviar_adjunto" &&
        !this.form.adjunto
      ) {
        return this.pushAppMessage({
          type: "warning",
          message: "Anexe el documento a enviar",
        });
      }
      this.modalConfirmacion = true;
    },
    async enviarCorreo() {
      const formData = convertToFormData(this.form);

      await this.services.Notificaciones.enviarNotificacionesCorreo(formData);

      this.modalConfirmacion = false;

      this.cerrarForm();
    },
    cerrarForm() {
      this.modalFormulario = false;
      this.form = {
        perfiles: [],
        todos: false,
        mensaje: "",
        asunto: "",
      };
    },
    async listarPerfiles() {
      const { data } = await this.services.Notificaciones.listarPerfiles();
      this.perfiles = data;
    },
  },
  async mounted() {
    await this.listarPerfiles();
  },
};
</script>
